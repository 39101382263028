<template>
    <div style="width: fit-content; background-color: white;">
        <div>
            <b-sidebar id="sidebar-help" title="Help" shadow right style="background-color: rgba(255, 0, 0, 0.5);">
                <div style="padding: 1rem;">
                    <h2 class="pb-5" style="font-weight: 600;">Futures Chart</h2>
                    <h3 class="pb-3">Indicators:<br>
                        <span style="font-size: 12px;">(the following indicators are available) </span>
                    </h3>
                    <h4><b>ATR: </b>Average True Range</h4>
                    <h4><b>BB: </b>Bollinger Bands</h4>
                    <h4><b>COT: </b>Commitment of Traders</h4>
                    <h4><b>MACD: </b>Moving Average Convergence / Divergence</h4>
                    <h4><b>CCI: </b>Commodity Channel Index</h4>
                    <h4><b>RSI: </b>Relative Strange Index</h4>
                    <h4><b>SMA: </b>Simple Moving Average</h4>
                    <h4><b>VOL: </b>Volume and Open Interest</h4>
                    <h4><b>VP: </b>Volume Profile</h4><h4>Seasonals are made using the "average percentage method".</h4>
                    <h3 class="pt-5">Tools:</h3>
                    <h4><b>Show/Hide Label: </b>show or hide information labels on the chart.</h4>
                    <h4><b>P/L (Profit &amp; Loss calculator): </b>to measure the price difference in USD between two points selected with the mouse.</h4>
                    <h4><b>Download and Annotations: </b>accessible via the icon with a symbol at the top left of the graph</h4>
                    <hr>
                    <h4>Need support? Visit the Support page or <a href="/support">click here</a></h4>
                </div>
            </b-sidebar>
        </div>        

          <b-container fluid style="width: auto; background-color: white; margin-left: -10px;">
                <b-row align-h="start" align-v="end" style="background-color: white; padding: 1px" class="row no-gutters">

    <b-col>
          <commodity-box v-bind:namespace="namespace">
                <template v-slot:contract-aligner>
                    <contract-aligner-selector v-bind:namespace="namespace" v-bind:title="'Aligner/Selector'"></contract-aligner-selector>     
                </template>
            </commodity-box>
    </b-col>
    <b-col sm="auto" style="margin: 0 0 0 -12px">
        <b-button-group style="border: 1px solid gray; padding: 1px; margin: 2px 1px 0px 1px; white-space: nowrap;
                            background-color: white; border-radius: 3px; vertical-align: top">     
                <span id="chartcontrols" class="some-class" style="border: 0px solid gray; background-color: white; margin: 1.5px"></span>
               
                <seasonal-selector v-bind:namespace="namespace"></seasonal-selector>
                <toggle-single-throw v-bind:namespace="namespace" v-if="$store.state[namespace].stockGraphType === 'line'"
                                     v-bind:property="'showBullets'" v-bind:label="'bullets'" style="margin: 0px;"></toggle-single-throw>
                <toggle-single-throw v-if="['admin', 'superadmin'].includes($store.state.user.role)" v-bind:namespace="namespace"
                                     v-bind:property="'showPlaybackControl'" v-bind:label="'playback'" style="margin: 0 0 0 0px"></toggle-single-throw>
                <add-to-portfolio-button v-bind:namespace="namespace" style="margin: 1px 0 0 0"></add-to-portfolio-button>            
              <!--   <b-button v-b-toggle.sidebar-help variant="warning" size="sm" style="border: 1px solid darkgray; margin: 1px;  border-radius: 3px;">
                    Help</b-button> -->
            </b-button-group>
    </b-col>
  </b-row>
</b-container>

    </div>
</template>

<style>
    #id {
        border-spacing: 10px;
        border-collapse: separate;
    }
</style>

<script>
    import commodityBox from '@/components/commodity-box';
    import contractAlignerSelector from '@/components/contract-aligner-selector';
    import seasonalSelector from '@/components/seasonal-selector';
    import toggleSingleThrow from '@/components/toggle-single-throw';
    import addToPortfolioButton from '@/components/add-to-portfolio-button';

    export default {
        components: {
            commodityBox,
            contractAlignerSelector,
            seasonalSelector,
            toggleSingleThrow,
            addToPortfolioButton
        },
        created() {

        },
        mounted() {
            console.log("basic-chart-input.vue mounted() starting. this.namespace=", this.namespace);

            let observer = new ResizeObserver(entries => {
                // console.log("ResizeObserver() starting. this.namespace=", this.namespace, " activeModuleName=", this.$store.getters['activeModuleName']);
                if (this.$store.getters['activeModuleName'] === this.namespace) {
                    // console.log("checking input height.");
                    for (let entry of entries) {
                        // console.log("entry=", entry);
                        // Now do something with the resized element
                        // console.log("entry.contentRect.height=", entry.contentRect.height);
                        this.$store.commit(this.namespace + '/browserSideOnly/setInputDivHeight', entry.contentRect.height);
                    }
                }
            });
            observer.observe(document.querySelector('#' + this.namespace + 'InputDiv'));
        },
        props: ['namespace'],
        computed: {
            symbols() {
                return this.$store.state.user.symbols;
            }
        },
        data: function () {
            return{
                dataSourceOptions: [
                    {value: 'barchart', text: 'barchart'},
                    {value: 'eod', text: 'eod'}
                ]
            }
        }
    };

</script>
